import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Page, Button, Toolbar, BackButton, Modal } from 'react-onsenui';
import * as UserActions from '../../actions/user';
import * as ShopActions from '../../actions/shop';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CartForm from './CartForm';
import Loading from '../Loading';
import { selectUserPaymentMethods } from '../../reducers/user';
import OrderDrinkForm from '../drink/OrderDrinkForm';
import { GET_USER_PAYMENT_METHODS } from '../../actionTypes';
import PaymentMethodsForm from '../preferences/PaymentMethodsForm';

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      purchased: false,
      purchaseRef: null,
      errorMessage: null,
      errors: null,
      paymentMethodsLoaded: false,
      newCardSelected: true,
      selectedPaymentMethod: null,
      discountCode: '',
      discount: null,
      newPaymentMethodIsOpen: false,
      existingPaymentMethodIsOpen: false
    }
  }

  componentDidMount() {
    const { actions, user } = this.props;

    actions.getUserPaymentMethods(user.id).then(response => {
      console.log(response);

      let newState = { paymentMethodsLoaded: true, newCardSelected: true }

      if (response.type === GET_USER_PAYMENT_METHODS && response.payload.data.length) {

        const selectedPaymentMethod = user.default_payment_method_id 
          ? response.payload.data.find(p => p.id === user.default_payment_method_id)
          : response.payload.data[0];

        newState = {
          ...newState,
          newCardSelected: false,
          selectedPaymentMethod
        }
      }

      this.setState(newState);
    });
  }

  handleBackPressed = () => {
    const { popPage, quickOrder } = this.props;

    if (quickOrder) {
      this.handleClear();
    }

    this.setState({step: 0});
    popPage();
  }

  handlePaymentOptionClicked = () => {
    this.setState({ newCardSelected: !this.state.newCardSelected });
  }

  handlePaymentMethodClicked = paymentMethod => {
    this.setState({ 
      selectedPaymentMethod: paymentMethod,
      existingPaymentMethodIsOpen: false 
    });
  }

  handleNewPaymentMethodClicked = () => {
    this.setState({ 
      newPaymentMethodIsOpen: true, 
      existingPaymentMethodIsOpen: false 
    });
  }

  handleExistingPaymentMethodClicked = () => {
    this.setState({ existingPaymentMethodIsOpen: true })
  }

  handleCloseExistingPaymentMethodClicked = () => {
    this.setState({ existingPaymentMethodIsOpen: false })
  }
  
  handlePaymentMethodFormSubmitted = values => {
    const { user, actions } = this.props;

    this.setState({ submitting: true, paymentMethodsLoaded: false });

    actions.addUserPaymentMethod(user.id, values).then(response => {
      console.log(response);

      if (response.payload.message === 'success') {
        this.setState({ selectedPaymentMethod: response.payload.data });
      }
    
      this.setState({ 
        submitting: false, 
        newPaymentMethodIsOpen: false,
        paymentMethodsLoaded: true,
      });
    });
  }

  renderToolbar = () => {
    const { pushPage, user } = this.props;

    return (
      <Toolbar position="top" >
        <div className="left">
          <BackButton onClick={this.handleBackPressed}>
            Back
          </BackButton>
        </div>
        <div className="center">
          <div className="c-navbar__content">
            {process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'staging' ?
              <h1>Quicky Staging</h1>
              : <img alt="Quicky" className="c-navbar__logo u-mr-05" src="/images/logo.png" />}
          </div>
        </div>
        <div className="right">
          <p className="c-button__icon-num">{user.cartItems.length}</p>
          <Button 
            className="cart-button" 
            modifier="material--flat"
          >
            <svg version="1.1" id="svg__cup-with-steam" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 135 234.2">
              <g id="cup">
                <polygon className="st0" points="21.6,228.8 4.6,81.8 129.4,81.8 112.4,228.8"/>
                <path d="M128.9,82.3l-16.9,146H22L5.1,82.3H128.9 M130,81.3H4l17.1,148h91.8L130,81.3L130,81.3z"/>
              </g>
              <g id="steam">
                <path className="st1" d="M51.6,5.4c0,0-8.4,12.1-2.3,23.4c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.9-2,23.9"/>
                <path className="st1" d="M63,5.4c0,0-8.4,12.1-2.3,23.5c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.9-2,23.9"/>
                <path className="st1" d="M74.5,5.4c0,0-8.4,12.2-2.3,23.6c6.5,12.2,7.3,10.7,13.6,23.7c6.1,12.5-2,23.8-2,23.8"/>
              </g>
              <g id="liquid_full">
                <polygon className="st2" points="112.1,228.3 21.9,228.3 6.7,96.3 127.2,96.3"/>
              </g>
            </svg>
          </Button>
        </div>  
      </Toolbar>
    );
  }

  handleBackToHomePressed = () => {
    const { resetPageStack, quickOrder } = this.props;

    if (quickOrder) {
      this.handleClear();
    }

    resetPageStack();
  }

  handleProductIncrement = index => {
    const { user, actions } = this.props;

    if (user.cartItems[index]) {
      actions.updateCartItem(index, user.cartItems[index].number + 1);
    }
  }

  handleProductDecrement = index => {
    const { user, actions } = this.props;

    if (user.cartItems[index]) {
      const number = user.cartItems[index].number > 0 
        ? user.cartItems[index].number-1
        : 0;
  
      actions.updateCartItem(index, number);
    }
  }

  handleVariationChange = (index, typeId, variationId) => {
    const { actions } = this.props;

    actions.updateCartItemVariation(index, typeId, variationId);
  }

  handlePickupTimeChanged = (newPickupTime) => {
    const { actions } = this.props;

    actions.updatePickupTime(newPickupTime);
  }

  handleExtraRemove = (index, extraId) => {
    const { user, actions } = this.props;

    if (user.cartItems[index].extras) {
      let newExtras = [...user.cartItems[index].extras];
      let extraIndex = newExtras.indexOf(extraId);

      if (extraIndex !== -1) {
        newExtras.splice(extraIndex, 1);
        
        actions.updateCartItemExtras(index, newExtras);
      }
    }
  }

  handleExtraAdd = (index, extraId) => {
    const { user, actions } = this.props;

    let extras = [extraId];

    if (user.cartItems[index].extras) {
      extras = [
        ...user.cartItems[index].extras,
        extraId,
      ];
    }

    actions.updateCartItemExtras(index, extras);
  }

  handleNotesChanged = (index, notes) => {
    const { actions } = this.props;

    actions.updateCartItemNotes(index, notes);
  }

  handleRemove = (index) => {
    this.props.actions.removeCartItem(index);
  }

  handleClear = () => {
    this.props.actions.clearCartItems();
  }

  handleShop = () => {
    const { pushPage, selectedShop, user } = this.props;

    pushPage(OrderDrinkForm, `order-form-${user.cartItems.length}`, { shopId: selectedShop.id });
  }

  handlePurchase = (paymentMethod, favourite) => {
    const { user, actions, resetPageStackToCurrentOrder } = this.props;
    const { discount } = this.state;

    if (!paymentMethod.error) {
      let data = {payment_method: paymentMethod, cart: user.cartItems, favourite: favourite, pickup_time: user.pickupTime};

      if(discount) {
        data.discount_id = discount.id;
      }

      return actions.purchase(data).then(response => {
        console.log(response);
  
        if (response.payload.errors) {
          this.setState({
            submitting: false,
            errorMessage: response.payload.message,
            errors: response.payload.errors,
          });
        
        } else {
          this.setState({
            submitting: false,
            purchased: true,
            purchaseRef: response.payload.data[0].id,
            errorMessage: null,
            errors: null
          });

          actions.clearCartItems();

          resetPageStackToCurrentOrder();
        }
      });
    } else {
      this.setState({
        errorMessage: paymentMethod.error.message,
        submitting: false
      });
    }
  }

  handleDiscountCodeChange = code => {
    this.setState({discountCode: code});
  }

  handleDiscountCodeApply = () => {
    this.props.actions.getDiscountByCode(this.state.discountCode).then(r => {
      this.setState({discountCode: '', discount: r.payload.discount});
    });
  }

  render() {
    const { user, selectedShop, paymentMethods } = this.props;
    const { submitting, errorMessage, newCardSelected, selectedPaymentMethod, paymentMethodsLoaded, discountCode, discount, newPaymentMethodIsOpen, existingPaymentMethodIsOpen } = this.state;

    let total = 0;

    let s = [];
    let cartItemIndex = 0;

    if(user.shops.length && Object.keys(user.cartItems).length) {

      s = user.shops.reduce((acc, shop) => {
        let add = false;

        if (shop.id == selectedShop) {
          let newCat = shop.categories.reduce((acc2, cat) => {
            let newProducts = user.cartItems.filter(cartItem => cartItem.category_id == cat.id)
              .map(cartItem => {                
                let product = cat.products.find(p => p.id == cartItem.product_id);
                const cartItemVariationValues = Object.values(cartItem.variations);
                
                let newProduct = {
                  ...product,
                  availableVariations: product.variations,
                  availableExtras: product.extras,
                  ...cartItem,
                  index: cartItemIndex,
                  number: cartItem.number
                };
  
                let price = Number(product.price);

                if (product.variations.length && product.variations) {
                  product.variations.map(variation => {
                    if(cartItemVariationValues.includes(variation.id)) {
                      price += Number(variation.pivot.price);
                    }
                  })
                }
  
                if (product.extras.length && cartItem.extras) {
                  cartItem.extras.map(id => {
                    let extra = product.extras.find(e => e.id == id);
                    price += Number(extra.pivot.price);
                  });
                }
  
                newProduct.price = (price * newProduct.number);
  
                total += Number(newProduct.price);
  
                cartItemIndex++;
  
                return newProduct;
              });
  
            if(newProducts.length) {
              add = true;
  
              acc2.push({...cat, products: newProducts});
            }
  
            return acc2;
          }, []);
  
          if(add) acc.push({...shop, categories: newCat});
        }

        return acc;
      }, [])
    }

    return (
      <Page className="cart" renderToolbar={this.renderToolbar}>
        <h1>Almost there!</h1>

        <Button className="c-btn white" onClick={this.handleBackPressed}>Order another drink?</Button>

        {s.length ? (
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
            <Elements>
              <CartForm 
                submitting={submitting}
                handleSubmitting={() => this.setState({submitting: true})}
                shops={s}
                handleVariationChange={this.handleVariationChange}
                handleExtraAdd={this.handleExtraAdd}
                handleExtraRemove={this.handleExtraRemove}
                handleProductIncrement={this.handleProductIncrement}
                handleProductDecrement={this.handleProductDecrement}
                handlePickupTimeChanged={this.handlePickupTimeChanged}
                handleNotesChanged={this.handleNotesChanged}
                total={total}
                handleRemove={this.handleRemove}
                handleClear={this.handleClear}
                handlePurchase={this.handlePurchase}
                handleShop={this.handleShop}
                errorMessage={errorMessage}
                initialPickupTime={user.pickupTime}
                discountCode={discountCode}
                handleDiscountCodeChange={this.handleDiscountCodeChange}
                handleDiscountCodeApply={this.handleDiscountCodeApply}
                discount={discount}
                paymentMethodsLoaded={paymentMethodsLoaded}
                onPaymentMethodClicked={this.handlePaymentMethodClicked}
                onSelectedPaymentOptionClicked={this.handlePaymentOptionClicked}
                onNewPaymentMethodClicked={this.handleNewPaymentMethodClicked}
                newCardSelected={newCardSelected}
                selectedPaymentMethod={selectedPaymentMethod}
                paymentMethods={paymentMethods}
                existingPaymentMethodIsOpen={existingPaymentMethodIsOpen}
                onExistingPaymentMethodClicked={this.handleExistingPaymentMethodClicked}
                onCloseExistingPaymentMethodClicked={this.handleCloseExistingPaymentMethodClicked}
              />
            </Elements>
          </StripeProvider>
        ) : (
          <div className="u-text-center u-text-black">
            {Object.keys(user.cartItems).length 
              ? <Loading />
              : <p>Nothing here yet!</p>
            }
          </div>
        )}

        <Modal isOpen={newPaymentMethodIsOpen}>
          <div className="c-modal c-modal--lg u-text-left">
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
              <Elements>
                <PaymentMethodsForm
                  onSubmit={this.handlePaymentMethodFormSubmitted}
                  submitting={submitting}
                />
              </Elements>
            </StripeProvider>

            <div className="c-form">
              <Button 
                type="button" 
                onClick={() => this.setState({ newPaymentMethodIsOpen: false })}
                className="u-mt-1 button--large--cta"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      </Page>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    selectedShop: state.shops.selectedShop,
    paymentMethods: selectUserPaymentMethods(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions, ...ShopActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)